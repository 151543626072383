
$baseFontSize: 14;
$baseFontFamily: "IBM Plex Sans";
$toolbarHeight: 80px;

$colorGray-1: #f5f5f5;
$colorGray-2: rgba(0, 0, 0, 0.15);
$colorAccent: #0093ff;
$colorPrimary: #0171bb;


//$baseFontSize: 14;
//$baseFontFamily: "IBM Plex Sans";
//$appHeaderSideMargin: 60 - 16;
//$colorGrayLight: #f5f5f5;
//$colorAccent: #009797;
//$colorPrimary: #001c39;
//$colorBlue: #004c97;
//
//$colorGray: rgba(0, 0, 0, 0.87);
//$colorMediumGray: rgba(0, 0, 0, 0.54);
//$colorGrayLightStronger: rgba(0, 0, 0, 0.35);
