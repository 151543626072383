@import "variables";
@import "normalize.css/normalize.css";
@import "utilities";
@import "side-panel";


* {
  box-sizing: border-box;
  outline: none;
}

html, body { height: 100%; font-size: $baseFontSize+px}
body { margin: 0; font-family: $baseFontFamily, sans-serif; font-size: $baseFontSize+px}
a, a:visited {
  text-decoration: none;
  color: $colorAccent;
}


td.dcol-table__action-column {
  background: transparent !important;
  padding: 0;
  padding-right: 1rem;
  width: 8rem;
  color: rgba(0, 0, 0, 0.3);
}

.dcol-icon-red:hover {
  color: #df0050;
}

.dcol-icon-orange:hover {
  color: darkorange;
}
