
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'node_modules/@angular/material/index' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-typography-config($font-family: '"Arial", sans-serif;');
@include mat.core($custom-typography);

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
//@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$odzykator-primary-palette: (
  50 : #e1eef7,
  100 : #b3d4eb,
  200 : #80b8dd,
  300 : #4d9ccf,
  400 : #2786c5,
  500 : #0171bb,
  600 : #0169b5,
  700 : #015eac,
  800 : #0154a4,
  900 : #004296,
  A100 : #c1d8ff,
  A200 : #8eb7ff,
  A400 : #5b97ff,
  A700 : #4187ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);


$odzykator-accent-palette: (
  50 : #e0f2ff,
  100 : #b3dfff,
  200 : #80c9ff,
  300 : #4db3ff,
  400 : #26a3ff,
  500 : #0093ff,
  600 : #008bff,
  700 : #0080ff,
  800 : #0076ff,
  900 : #0064ff,
  A100 : #ffffff,
  A200 : #f2f6ff,
  A400 : #bfd4ff,
  A700 : #a6c3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$odzyskator-primary: mat.define-palette($odzykator-primary-palette);
$odzyskator-accent: mat.define-palette($odzykator-accent-palette, 500);

// The warn palette is optional (defaults to red).
$odzyskator-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$odzyskator-theme: mat.define-light-theme((
  color: (
    primary: $odzyskator-primary,
    accent: $odzyskator-accent,
    warn: $odzyskator-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($odzyskator-theme);

//CUSTOMIZATION

.mat-card-header-text {
  height: auto;
  margin: 0 !important;
}


